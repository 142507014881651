.home {
    width: 100%;
    margin-top: 10px;
    background-color: whitesmoke;

}.home.blur {
     width: 100%;
     margin-top: 10px;

     -webkit-filter: blur(5px);
     /*-moz-filter: blur(5px);*/
     /*-o-filter: blur(5px);*/
     backdrop-filter: blur(10px);
     -ms-filter: blur(5px);
     filter: blur(5px);
     background-color: #ccc;
 }

.homeWidgets {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
    .home {
        width: calc(100vw - 80px);
    }

    .homeWidgets {
        display: none;
    }
}