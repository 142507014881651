.sidebarIconSettings{
    font-size: 20px !important;
    flex: 10%;
}

.sidebarListItemSettings{
    text-decoration: none;
    color: black;
    padding: 5px 0;
    margin-bottom: 2px;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    align-items: center;
    width: 100%;
    height: 20px;
    border-radius: 5px;

}
.sidebarListItemSettings.active{
    /*background-color: #398DD2;*/
    border: 1px solid #398DD2;
    pointer-events: none;
    /*border-right-color: #2ff501;*/
}
.sidebarListItemSettings:hover{
    background-color: #398DD2;
    /*border-right-color: #2ff501;*/
}

.sidebarPageNameSettings{
    flex: 70%;
    pointer-events: none;
    padding:2px;
}