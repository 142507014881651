@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@media screen and (min-width: 860px)  {
  .register .col-2 img{
    display: initial;
  }
}

.cont{
  display: flex;
}
.App{
  flex-grow: 1;
}

.bottom-right-div {
  position: fixed;
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 100; /* Adjust the z-index value based on your needs */
  /*background-color: #fff; !* Background color of the div *!*/
  padding: 10px;
  /*border: 1px solid #ccc; !* Add a border for visibility *!*/
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); !* Optional: Add a box shadow for a subtle effect *!*/
}

@media only screen and (max-width:700px)  {
  .cont{
    width: 100vw;
  }
}


/*  Test notifications*/